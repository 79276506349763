// ----------------------------------------
//  Object - Utylity - line-height
// ----------------------------------------

.c-text-line {
    line-height: $font-line-height;
    @include lineHeightCrop($font-line-height); // $font-line-height = 2
}
.c-text-line-half {
    line-height: $font-line-height-half;
    @include lineHeightCrop($font-line-height-half); // $leading-tight = 1.5
}

@include _mq-down(md) {
    .c-text-line-half_sp {
        line-height: $font-line-height-half;
        @include lineHeightCrop($font-line-height-half); // $leading-tight = 1.5
    }
}

 /* #Footer
   -------------------------------------------------------------------------- */
/*.l-footer {}
.l-footer__copryright-area {}
.l-footer__copryright {}
*/
body:not(#home) .home-totop {
    display: none;
}
body#home .page-totop {
    display: none;
}

body:not(#home) #footer {
    padding-top: 140px;
    background: #f0f0f0;
    @include _mq-down(md) {
        padding-top: 0;
        background: transparent;
        background: #fff;
    }
}

body:not(#home) .ft-Container {
    height: auto;
    overflow-y: visible;
    padding: 130px 0 40px;
    @include _mq-down(sm) {
        padding: 100px 0 0px;
    }
}

body:not(#home) .totop {
    position: fixed;
    top: auto;
    bottom: 30px;
    right: 30px;
    z-index: 1;
    opacity: 1;
    transition: 0.4s;
    @include _mq-down(md) {
        width: 35px;
        height: 35px;
        bottom: 40px;
        right: 15px;
        border: 1px solid #fff;
        &::after {
            width: 15.5px;
            height: 18px;
        }
    }
    &.hide {
        z-index: -1;
        opacity: 0;
    }
    @include _mq-up(md) {
        &.move {
            bottom: 103px;
        }
    }
}

@include _mq-down(md) {
    body#support #footer {
        padding-top: 100px;
    }
}

/*トップページ*/
@include _mq-down(md) {
    #home .sec-Footer {
        display: block;
    }
}

.sec-Footer {
    background: #fff;
}
.ft-Container {
    position: absolute;
    bottom: 0;
    background: linear-gradient(to right, #e6140a, #8c0005);
    width: 100%;
    height: _vw(575);
    padding: _vw(127) 0 _vw(42);
    @include _mq-up(xxl) {
        height: 575px;
        padding: 127px 0 42px;
    }
    @include _mq-down(sm) {
        height: auto;
        padding: _vw(100, 375) 0 0;
        position: static;
        //overflow-y: scroll; //これではダメ
        .contents__box {
            height: auto;
        }
    }
}

/*下層ページ時*/
body:not(#home) .ft-Container {
    position: static;
}

.ft-Inr {
    width: _vw(1200);
    margin: 0 auto;
    height: auto;
    //background: #ddd;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include _mq-up(xxl) {
        width: 1200px;
    }
    @include _mq-down(sm) {
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        display: block;
    }
    .logo {
        width: _vw(333);
        @include _mq-up(xxl) {
            width: 333px;
        }
        @include _mq-down(sm) {
            width: _vw(240, 375);
            margin: 0 auto;
        }
    }
    .freedial {
        width: _vw(368);
        @include _mq-up(xxl) {
            width: 368px;
        }
        @include _mq-down(sm) {
            width: 100%;
        }
    }
    .contact {
        margin-top: _vw(97);
        font-size: _vw(15);
        @include _mq-up(xxl) {
            margin-top: 97px;
            font-size: 15px;
        }
        @include _mq-down(sm) {
            margin-top: _vw(50, 375);
            font-size: _vw(15, 375);
        }
        p {
            font-size: _vw(15);
            color: #fff;
            letter-spacing: 0.05em;
            @include _mq-up(xxl) {
                font-size: 15px;
            }
            @include _mq-down(sm) {
                font-size: _vw(15, 375);
                text-align: center;
            }
        }
        img {
            margin: _vw(15) 0;
            @include _mq-up(xxl) {
                margin: 15px 0;
            }
            @include _mq-down(sm) {
                margin: _vw(12, 375) 0;
            }
        }
    }
    .sns {
        text-align: center;
        p {
            font-size: _vw(12);
            text-align: center;
            display: inline-block;
            position: relative;
            padding: 0 _vw(20);
            color: #fff;
            letter-spacing: 0.1em;
            &::before, &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
                width: _vw(16);
                height: 1px;
                background: #fff;
            }
            &::before {
                left: 0;
            }
            &::after {
                right: 0;
            }
            @include _mq-up(xxl) {
                font-size: 12px;
                padding: 0 20px;
                &::before, &::after {
                    width: 16px;
                }
            }
            @include _mq-down(sm) {
                margin-top: _vw(45, 375);
                font-size: _vw(12, 375);
                padding: 0 _vw(20, 375);
                &::before, &::after {
                    width: _vw(16, 375);
                }

            }
        }
    }
    .sns-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: _vw(215);
        margin-top: _vw(20);
        gap: _vw(20);
        @include _mq-up(xxl) {
            width: 215px;
            margin-top: 20px;
            gap: 20px;
        }
        @include _mq-down(sm) {
            width: _vw(215, 375);
            margin: _vw(20, 375) auto 0;
            gap: _vw(20, 375);
        }
        li.line a {
            background: url("../../assets/img/common/icon_line.svg") center top / _vw(25) _vw(24) no-repeat;
            width: _vw(40);
            @include _mq-up(xxl) {
                background-size: 25px 24px;
                width: 40px;
            }
            @include _mq-down(sm) {
                background-size: _vw(25, 375) _vw(24, 375);
                width: _vw(40, 375);
            }
        }
        li.insta a {
            background: url("../../assets/img/common/icon_instagram.svg") center top / _vw(24) _vw(24) no-repeat;
            width: _vw(75);
            @include _mq-up(xxl) {
                background-size: 24px 24px;
                width: 75px;
            }
            @include _mq-down(sm) {
                background-size: _vw(24, 375) _vw(24, 375);
                width: _vw(75, 375);
            }
        }
        li.twitter a {
            background: url("../../assets/img/common/icon_twitter.svg") center top / _vw(28) _vw(23) no-repeat;
            width: _vw(50);
            @include _mq-up(xxl) {
                background-size: 28px 23px;
                width: 50px;
            }
            @include _mq-down(sm) {
                background-size: _vw(28, 375) _vw(23, 375);
                width: _vw(50, 375);
            }
        }
        li.facebook a {
            background: url("../../assets/img/common/icon_facebook.svg") center top / _vw(24) _vw(24) no-repeat;
            width: _vw(70);
            @include _mq-up(xxl) {
                background-size: 24px 24px;
                width: 70px;
            }
            @include _mq-down(sm) {
                background-size: _vw(24, 375) _vw(24, 375);
                width: _vw(70, 375);
            }
        }
        li.youtube a {
            background: url("../../assets/img/common/icon_youtube.svg") center top / _vw(26) _vw(19) no-repeat;
            background-position: center top _vw(3);
            width: _vw(65);
            @include _mq-up(xxl) {
                background-position: center top 3px;
                background-size: 26px 19px;
                width: 65px;
            }
            @include _mq-down(sm) {
                background-position: center top _vw(3, 375);
                background-size: _vw(26, 375) _vw(19, 375);
                width: _vw(65, 375);
            }
        }
        li a {
            font-size: _vw(13);
            letter-spacing: 0.05em;
            color: #fff;
            text-align: center;
            display: inline-block;
            //width: 80px;
            height: _vw(46);
            position: relative;
            @include _mq-up(xxl) {
                font-size: 13px;
                height: 46px;
            }
            @include _mq-down(sm) {
                font-size: _vw(13, 375);
                height: _vw(46, 375);
            }
            @include _mq-up(md) {
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        li a span {
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }
}

.ft-groupsite {
    width: _vw(1260);
    height: _vw(66);
    border: 1px solid #fff;
    margin: _vw(30) auto 0;
    display: flex;
    @include _mq-up(xxl) {
        width: 1260px;
        height: 66px;
        margin: 30px auto 0;
    }
    @include _mq-down(sm) {
        width: 100%;
        height: auto;
        margin: _vw(30, 375) auto 0;
        display: block;
        border: 0;
    }
    & .group {
        a {
            font-size: _vw(13);
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            width: _vw(290);
            height: 100%;
            color: $color-brand;
            background: #fff;
            @include _mq-up(xxl) {
                font-size: 13px;
                width: 290px;
            }
            @include _mq-up(md) {
                &:hover {
                    background: rgba(#fff, 0.8);
                }
            }
            @include _mq-down(sm) {
                font-size: _vw(13, 375);
                width: 100%;
                height: _vw(50, 375);
            }
        }
    }
    & .inr {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        line-height: 1;
        padding: _vw(10) 0;
        @include _mq-up(xxl) {
            padding: 10px 0;
        }
        @include _mq-down(sm) {
            padding: 0;
        }
        li {
            display: inline-block;
            margin-left: _vw(20);
            line-height: 1;
            @include _mq-up(xxl) {
                margin-left: 20px;
            }
            @include _mq-down(sm) {
                border-bottom: 1px solid rgba(255,255,255,0.7);
                width: 50%;
                margin-left: 0;
                height: 50px;
                &:nth-child(even) {
                    border-left: 1px solid rgba(255,255,255,0.7);
                }
            }
            a {
                color: #fff;
                display: block;
                font-size: _vw(13);
                position: relative;
                padding-left: _vw(12);
                background: url("../../assets/img/common/icon_triangle_gray.svg") left center / _vw(5) _vw(7) no-repeat;
                @include _mq-up(xxl) {
                    font-size: 13px;
                    padding-left: 12px;
                    background-size: 5px 7px;
                }
                @include _mq-up(md) {
                    &:hover {
                        opacity: 0.7;
                    }
                }
                @include _mq-down(sm) {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    font-size: _vw(12, 375);
                    padding: 0 _vw(10, 375) 0 _vw(25, 375);
                    line-height: 1.3846;
                    background: url("../../assets/img/common/icon_triangle_white.svg") left _vw(10, 375) center / _vw(5, 375) _vw(7, 375) no-repeat;
                }
            }
        }
    }
}

.ft-lower {
    width: _vw(1260);
    margin: _vw(20) auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include _mq-up(xxl) {
        width: 1260px;
        margin: 20px auto 0;
    }
    @include _mq-down(sm) {
        width: 100%;
        margin: 0;
    }
    .nav {
        display: flex;
        flex-wrap: wrap;
        letter-spacing: 0.05em;
        li {
            display: inline-block;
            margin-left: _vw(20);
            line-height: 1;
            &:first-child {
                margin-left: 0;
            }
            a {
                color: #fff;
                display: block;
                font-size: _vw(12);
                position: relative;
                padding-left: _vw(10);
                background: url("../../assets/img/common/icon_triangle_white.svg") left center / _vw(5) _vw(7) no-repeat;
            }
            @include _mq-up(xxl) {
                margin-left: 20px;
                a {
                    font-size: 12px;
                    padding-left: 10px;
                    background-size: 5px 7px;
                }
            }
            @include _mq-up(md) {
                &:hover {
                    opacity: 0.7;
                }
            }
            @include _mq-down(sm) {
                margin: 0;
                width: 33.3333%;
                height: 44px;
                border-bottom: 1px solid rgba(255,255,255,0.7);
                &:nth-child(2), &:nth-child(3), &:nth-child(5){
                    border-left: 1px solid rgba(255,255,255,0.7);
                }
                &:nth-child(n+4) {
                    width: 50%;
                }
                a {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    font-size: _vw(11, 375);
                    padding: 0 _vw(10, 375) 0  _vw(25, 375);
                    background-size:  _vw(5, 375)  _vw(7, 375);
                    background-position: left  _vw(10, 375) center;
                }
            }
        }
    }
    .copy {
        text-align: right;
        letter-spacing: 0.05em;
        p {
            color: #fff;
            font-size: _vw(12);
            & + p {
                margin-top: _vw(8);
            }
            @include _mq-up(xxl) {
                font-size: 12px;
                & + p {
                    margin-top: 8px;
                }
            }
        }
        @include _mq-down(sm) {
            text-align: center;
            padding: 10px 0;
            margin: 0 auto;
            p {
                font-size: _vw(10, 375);
                line-height: 1.75;
            }
        }
    }
}

.totop-wrap {
    //position: relative;
}
.totop {
    width: 45px;
    height: 45px;
    background: $color-brand;
    border-radius: 50%;
    border: 2px solid #fff;
    position: absolute;
    top: -22px;
    right: 60px;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        background: url("../img/common/icon_totop.svg") center center / contain no-repeat;
        width: 19px;
        height: 22px;
    }
    @include _mq-up(md) {
        &:hover {
            background: lighten($color-brand, 15%);
        }
    }
    @include _mq-down(sm) {
        top: 80px;
        right: 20px;
    }
}





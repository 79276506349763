 /* #Breadcrumb
   -------------------------------------------------------------------------- */
/*.l-breadcrumb {}
.l-breadcrumb__lists {}
.l-breadcrumb__list {}
.l-breadcrumb__link {}
*/

.c-breadcrumb-wrap {
    width: 100%;
    border-bottom: 1px solid #000;
}

.c-breadcrumb {
    font-size: 1.2rem;
    padding-top: 15px;
    padding-bottom: 14px;
    line-height: 1;
    a {
        color: #000;
    }
    > span {
        display: inline-block;
    }
    > span + span {
        &::before {
            content: "｜";
            color: #000;
            display: inline-block;
            font-size: 1.2rem;
            margin-right: 5px;
            margin-left: 5px;
        }
    }
    > span:last-child {
        color: $color-brand;
        cursor: default;
    }
    @include _mq-down(md) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

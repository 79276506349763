// ============================================
// menu
// ============================================

.header-menu-area {
    .header-menu-trigger {
        width: 70px;
        height: 70px;
        background-color: $color-brand-accent;
        border: 0;
        padding: 0;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 9999;
        @include _mq-up(md) {
            &:hover {
                background-color: lighten($color-brand-accent, 7%);
            }
        }
        @include _mq-down(md) {
            width: 60px;
            height: 60px;
        }
    }
    .menu-trigger {
        width: 29px;
        height: 20px;
        margin: 0 auto;
        position: relative;
        top: 17px;
        > span {
            position: absolute;
            left: 0;
            display: inline-block;
            width: 100%;
            height: 2px;
            background-color: #fff;
            transition: 0.3s $ease-out-cubic;
        }
        > span:nth-child(1) {
            top: 0px;
        }
        > span:nth-child(2) {
            top: 8px;
        }
        > span:nth-child(3) {
            top: 16px;
        }
        @include _mq-down(md) {
            top: 15px;
            width: 25px;
        }
    }
    .menu-trigger-text {
        width: 100%;
        font-size: 0.9rem;
        line-height: 1;
        text-align: center;
        color: #fff;
        letter-spacing: 0.1em;
        span {
            display: inline-block;
            position: absolute;
            bottom: 14px;
            left: 0;
            right: 0;
            transition: 0.3s $ease-in-out;
        }
        span.text_mune {
            opacity: 1;
        }
        span.text_close {
            opacity: 0;
        }
        @include _mq-down(md) {
            font-size: 0.75rem;
            span {
                bottom: 13px;
            }
        }
    }
    /* MENUボタンclick時に".active"追加 */
    &.Active {
        .header-menu-trigger {
            background-color: #fff;
        }
        .menu-trigger {
            > span {
                background-color: $color-brand-accent;
            }
            > span:nth-child(1) {
                transform: translate3d(0, 8px, 0) rotate(-35deg);
            }
            > span:nth-child(2) {
                opacity: 0;
            }
            > span:nth-child(3) {
                transform: translate3d(0, -8px, 0) rotate(35deg);
            }
        }
        .menu-trigger-text {
            span {
                color: #5f6469;
            }
            span.text_mune {
                opacity: 0;
            }
            span.text_close {
                opacity: 1;
            }
        }
    }
    @include _mq-down(md) {
    }
}

.header-menu-Contact-lists {
    display: none;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, .3);
    @include _mq-down(md) {
        display: flex;
        justify-content: center;
        background: $color-brand;
        width: 280px;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    }
}
.Active {
    .header-menu-Contact-lists {
        visibility: visible;
        opacity: 1;
    }
}
.header-menu-Contact-list {
    width: 50%;
    a {
        display: flex;
        display: -ms-flexbox;
        height: 50px;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: #fff;
        @include _mq-down(md) {
            font-size: 1.1rem;
        }
    }
    i {
        margin-right: 0.9em;
    }
    &.contact i {
        width: 24px;
    }
    &.phone i {
        width: 16px;
    }
    &:first-child {
        @include _mq-down(md) {
            border-right: 1px solid rgba(0, 0, 0, .2);
        }
    }
}

.header-nav {
    position: absolute;
    top: 70px;
    right: 0;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    box-shadow: -5px 5px 30px rgba(0, 0, 0, .05);
    @include _mq-down(md) {
        top: 258px;
        width: 280px;
        position: static;
    }
    &.Active {
        visibility: visible;
        opacity: 1;
        @include _mq-up(md) {
            overflow-y: scroll;
            max-height: calc(100vh - 70px); //固定
        }
    }
}

.header-nav-lists {
}

.header-nav-list {
    border-bottom: 1px solid #F0F0F0;
    &:last-child {
        border-bottom: 0;
    }
    a, & > div {
        display: block;
        padding: 1.6em 3.8em 1.6em 2.6em;
        position: relative;
        cursor: pointer;
        @include _mq-down(md) {
            padding: 1.6em 0 1.6em 2.6em;
            font-size: 1.3rem;
        }
        &:hover {
        @include _mq-up(md) {
            opacity: .6;
            &:before {
                left: 1.4em;
            }
        }
    }
    &:before {
        display: inline-block;
        content: "";
        width: .4em;
        height: .4em;
        border-top: 1px solid $color-brand;
        border-right: 1px solid $color-brand;
        transform: rotate(45deg);
        position: absolute;
        top: 0;
        left: 1em;
        bottom: 0;
        margin: auto;
        transition: all .2s ease-in-out;
        }
    }
}

.l-gnav-background {
    width: 0vw;
    height: 0vh;
    background: rgba(0,0,0,0.1);
    position: fixed;
    z-index: -1;
    opacity: 0;
    top: 0;
    left: 0;
    transition: opacity 0.6s;
    &.Active {
        opacity: 1;
        width: 100vw;
        height: 100vh;
    }
}

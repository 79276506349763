// @desc - rem未対応ブラウザ用にpx指定を追加します。remのデフォルトは10px（font-size: 62.5%;)
// @example scss - Usage
//
// .foo { @include _font-rem(16); }
//
// @example css - CSS output
// .foo {
//   font-size: 16px;
//   font-size: 1.6rem;
// }
@mixin _font-rem($size: 16, $base: 10) {
    font-size: $size + px;
    font-size: ($size / $base) * 1rem;
}

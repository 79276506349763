@charset "UTF-8";
// ==========================================================================
// Foundation - デフォルトスタイルの初期化や、プロジェクトにおける基本的なスタイルを定義します。
// ==========================================================================
@import "foundation/variable/_background.scss";
@import "foundation/variable/_breakpoints.scss";
@import "foundation/variable/_easing.scss";
@import "foundation/variable/_font-family.scss";
@import "foundation/variable/_global.scss";
@import "foundation/variable/_icon.scss";
// @import "bourbon";
@import "foundation/mixin/_backgourd.scss";
@import "foundation/mixin/_center-text.scss";
@import "foundation/mixin/_centering.scss";
@import "foundation/mixin/_clearfix.scss";
@import "foundation/mixin/_fix-ratio.scss";
@import "foundation/mixin/_flex.scss";
@import "foundation/mixin/_flexbox-grid-mixins.scss";
@import "foundation/mixin/_font-rem.scss";
@import "foundation/mixin/_lineHeightCrop.scss";
@import "foundation/mixin/_mq-down.scss";
@import "foundation/mixin/_mq-retina.scss";
@import "foundation/mixin/_mq-up.scss";
@import "foundation/mixin/_on-event.scss";
@import "foundation/mixin/_overlay.scss";
@import "foundation/mixin/_polka-dot.scss";
@import "foundation/mixin/_ripple.scss";
@import "foundation/mixin/_size.scss";
@import "foundation/mixin/_stripes.scss";
@import "foundation/mixin/_svg-text.scss";
@import "foundation/mixin/_text-truncate.scss";
@import "foundation/mixin/_triangle.scss";
@import "foundation/mixin/_wrapper.scss";
@import "foundation/function/_preset.scss";
@import "foundation/function/_rem.scss";
@import "foundation/function/_strip-unit.scss";
@import "foundation/function/_vw.scss";
@import "foundation/vendor/_bourbon.scss";
@import "foundation/vendor/_normalize.scss";
@import "foundation/vendor/_web-fonts.scss";
@import "foundation/base/_base.scss";
@import "foundation/base/_print.scss";
/*@import "foundation/animation/*.scss";*/
//
//
// ==========================================================================
// Layout - ページを構成するヘッダーやメインのコンテンツエリア、サイドバーやフッターといった
//          プロジェクト共通のコンテナーブロックのスタイルを定義します。
// ==========================================================================
@import "layout/_breadcrumb.scss";
@import "layout/_btn.scss";
@import "layout/_contents.scss";
@import "layout/_footer.scss";
@import "layout/_header.scss";
@import "layout/_line-height.scss";
@import "layout/_main.scss";
@import "layout/_menu.scss";
@import "layout/_side.scss";
@import "layout/_wrapper.scss";
//
//
// ==========================================================================
// Object - プロジェクトにおける繰り返されるビジュアルパターンをすべてObjectと定義します。
// ==========================================================================
//
// --------------------------------------------------------------------------
// Component - ページ全体で横断的に再利用のできるような、小さな単位のモジュールを定義します。
//             使わないモジュールはコメントアウト
// --------------------------------------------------------------------------
/*@import "object/component/_avator.scss";
@import "object/component/_banner.scss";
@import "object/component/_button.scss";
@import "object/component/_divider.scss";*/
@import "object/component/_embed.scss";
/*@import "object/component/_form-checkbox.scss";
@import "object/component/_form-input.scss";
@import "object/component/_form-radio.scss";
@import "object/component/_form-select.scss";
@import "object/component/_form-textarea.scss";
@import "object/component/_grid.scss";
@import "object/component/_iconfont.scss";
@import "object/component/_label.scss";
@import "object/component/_link-dl.scss";
@import "object/component/_link-ext.scss";
@import "object/component/_link-pdf.scss";
@import "object/component/_link.scss";
@import "object/component/_list-note.scss";
*/
@import "object/component/_list.scss";
/*
@import "object/component/_mask.scss";
@import "object/component/_scroll.scss";
@import "object/component/_text-attention.scss";
@import "object/component/_text-emphasis.scss";
@import "object/component/_text-secondary.scss";*/
@import "object/component/_text.scss";
//
// --------------------------------------------------------------------------
// Project - プロジェクト固有のパターンで、複数のページで使い回せるようなコンポーネントです。
// --------------------------------------------------------------------------
@import "object/project/_container.scss";
@import "object/project/_loading.scss";
@import "object/project/_project.scss";
@import "object/project/_tab.scss";
@import "object/project/_title.scss";
//
//
// --------------------------------------------------------------------------
// Utility - ComponentとProjectレイヤーのObjectのモディファイアで解決することが難しい・適切では無い、わずかなスタイルの調整のための便利クラスなどを定義します。
//           使わないモジュールはコメントアウト
// --------------------------------------------------------------------------
/*@import "object/utility/_align.scss";
@import "object/utility/_background.scss";*/
@import "object/utility/_clearfix.scss";
/*@import "object/utility/_display.scss";*/
// @import "object/utility/_hide.scss";
/*@import "object/utility/_margin.scss";
@import "object/utility/_others.scss";
@import "object/utility/_position.scss";
@import "object/utility/_size.scss";
@import "object/utility/_text.scss";
@import "object/utility/_secure-height.scss";
@import "object/utility/_sr-only.scss";*/
//
// --------------------------------------------------------------------------
// Objectレイヤーの命名規則
// --------------------------------------------------------------------------
// Component - .c-*
// Project - .p-*
// Scope - .s-*
// Utility - .u-*
//
//
// ==========================================================================
// Page - 単一ページで使用するスタイルをPageレイヤーで定義します。
//        2ページ以上で同様のスタイルが発生した時はProjectレイヤーに移動を検討。
//        どうしても記述量が多くなりそうな場合は個別cssファイルに分離します。
// ==========================================================================
//
// --------------------------------------------------------------------------
// Pageレイヤーの命名規則
// --------------------------------------------------------------------------
// pg-*
// ただし、個別cssファイルに分離する場合はプレフィックス無しでの命名を許容します。


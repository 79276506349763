/* #Embed
   -------------------------------------------------------------------------- */
/*doc
---
name: Embed
category: Component
tag: Embed
---

Youtubeなどをレスポンシブ対応させます。
デフォルト縦横比率は9:16です。

```ejs
<div class="c-embed">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/1OKZOV-iLj4"></iframe>
</div>
```
*/

.c-embed {
    display: block;
    overflow: hidden;
    position: relative;
    height: 0;
    padding-bottom: percentage(9 / 16);
    &__item,
    > iframe,
    > embed,
    > object,
    > video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

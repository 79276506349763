 /* #Text
   -------------------------------------------------------------------------- */
/*doc
---
name: Mincho
category: Component
tag: Text
---

サイト全体で使うテキストスタイルを定義します。

```ejs
<p class="c-text-mincho">ダミーテキストです。</p>
```
*/

.c-text-mincho {
  font-family: $font-family-serif;
}
.c-text-gothic {
  font-family: $font-family-sans-serif;
}

.c-text-Gothem {
    font-family: 'Gotham', 'Montserrat', sans-serif;
}
.c-text-A1gothic {
    font-family: "A1ゴシック M", "A1 Gothic M", "Lato", "Noto Sans Japanese", "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
}
.c-text-notoserif {
    font-family: "Noto Serif", serif;
}

.c-text {
    font-size: 14px;
    line-height: 2;
    letter-spacing: 0.1em;
    text-align: justify;
    @include _mq-down(md) {
        font-size: 13px;
        line-height: 1.9231;
        letter-spacing: .05em;
    }
}

.c-color-brand {
    color: $color-brand;
}
.c-color-white {
    color: #fff;
}


.side-banner {
    position: fixed;
    top: 40%;
    right: 0;
    li.line a {
        background: url("../../assets/img/common/icon_line.svg") center top 12px / 22px 22px no-repeat;
        background-color: #8cc63f;
    }
    li.insta a {
        background: url("../../assets/img/common/icon_instagram.svg") center top 12px / 21px 21px no-repeat;
        background-color: #d4145a;
    }
    li.twitter a {
        background: url("../../assets/img/common/icon_twitter.svg") center top 13px / 24px 20px no-repeat;
        background-color: #29abe2;
    }
    li.facebook a {
        background: url("../../assets/img/common/icon_facebook.svg") center top 12px / 21px 21px no-repeat;
        background-color: #0071bc;
    }
    li.youtube a {
        background: url("../../assets/img/common/icon_youtube.svg") center top 14px / 25px 17px no-repeat;
        background-color: #c1272d;
    }
    li a {
        font-size: 8px;
        letter-spacing: 0.05em;
        color: #fff;
        text-align: center;
        display: block;
        width: 60px;
        height: 60px;
        background: #ccc;
        position: relative;
        @include _mq-up(md) {
            &:hover {
                opacity: 0.7;
            }
        }
    }
    li a span {
        position: absolute;
        bottom: 11px;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding-left: 0.05em;
    }
    @include _mq-down(md) {
        display: none;
    }
}

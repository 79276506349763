/* #ListNote
   -------------------------------------------------------------------------- */
/*doc
---
name: List
category: Component
tag: List
---

リストのデフォルトスタイルです。

```ejs
<ul class="c-list-asterisk">
  <li>1つ目のリスト</li>
  <li>2つ目のリスト</li>
</ul>
<ul class="c-list-asterisk c-list--inside">
    <li>リストをコンテンツの内側に収めたいときは</li>
    <li>".c-list--inside"をつける</li>
</ul>

クラス名counterのものは<ol>を使用する

```
*/

.c-list--inside {
    margin-left: 1em;
}

.c-list-asterisk { //「※」
    & li {
        position: relative;
        padding-left: 1em;
    }
    & li:before {
        content: '※';
        position: absolute;
        margin-left: -1em;
    }
}

.c-list-dot { //「・」
    & li {
        position: relative;
        padding-left: 1em;
    }
    & li:before {
        content: '・';
        position: absolute;
        margin-left: -1em;
    }
}

.c-list-square { //「■」
    & li {
        position: relative;
        padding-left: 1.1em;
    }
    & li:before {
        content: '■';
        position: absolute;
        margin-left: -1.1em;
    }
    &.c-brand li:before {
        color: $color-brand;
    }
}

.c-list-circle-white { //「⚪︎」
    & li {
        position: relative;
        padding-left: 1em;
    }
    & li:before {
        content: '⚪︎';
        position: absolute;
        margin-left: -1em;
    }
}

.c-list-circle { //「●」
    & li {
        position: relative;
        padding-left: 1em;
    }
    & li:before {
        content: '●';
        position: absolute;
        margin-left: -1em;
    }
}

.c-list-asterisk-counter { //「※1」「※2」
    counter-reset: number 0;
    & li {
        position: relative;
        padding-left: 2.5em;
    }
    & li:before {
        counter-increment: number 1;
        content: "※" counter(number);
        position: absolute;
        margin-left: -2.5em;
    }
}

.c-list-inject { //「注）」
    & li {
        position: relative;
        padding-left: 2em;
    }
    & li:before {
        content: '注）';
        position: absolute;
        margin-left: -2em;
    }
}

.c-list-inject-counter { //「1）」「2）」
    counter-reset: number 0;
    & li {
        position: relative;
        padding-left: 2em;
    }
    & li:before {
        counter-increment: number 1;
        content: counter(number) " ）";
        position: absolute;
        margin-left: -2em;
    }
}

.c-list-d-inject-counter { //「（1）」「（2）」
    counter-reset: number 0;
    & > li {
        position: relative;
        padding-left: 2em;
    }
    & > li:before {
        counter-increment: number 1;
        content: "（"counter(number) "）";
        position: absolute;
        margin-left: -2.5em;
    }
}

.c-list-inject-parenth-counter { //「注1）」「注2）」
    counter-reset: number 0;
    & li {
        position: relative;
        padding-left: 3em;
    }
    & li:before {
        counter-increment: number 1;
        content: "注" counter(number) " ）";
        position: absolute;
        margin-left: -3em;
    }
}

.c-list-period-counter { //「1.」「2.」
    counter-reset: number 0;
    & li {
        position: relative;
        padding-left: 1.67em;
    }
    & li:before {
        counter-increment: number 1;
        content: counter(number) ". ";
        position: absolute;
        margin-left: -1.5em;
    }
}

.c-list-circle-number { // ①、② 打ち文字
    padding-left: 1em;
    span {
        margin-left: -1.1em;
        //padding-right: 0.1em;
    }
}
